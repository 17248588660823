
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from "react-player";
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

const Videox = Styled.div`
    text-align: center;
    margin: 0 auto;
    position: relative;
    & > h2 {
        text-shadow: 0 0 1px #000, 0 0 2px #000;
    }
    
`;

function Registration() {
    return (
        <div id="Games" className="Games anchor">
            <Header />
            <Menu />      
            <Videox className="w-full lg:w-fit lg:pb-[22em] h-fit place-self-center py-[2em] pb-[8em] relative">
                <h2 className="w-full place-self-center text-[2em] lg:text-[3em] text-orange-500 py-8">Registration</h2>
                <ReactPlayer autoPlay
                    url="/videos/closing.mp4" playing={true}
                    controls className="lg:w-[100%] lg:h-[100%] lg:max-w-[100%] w-[100%] h-[100%] max-w-[100%]"
                />
            </Videox>
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Registration;