
import React from 'react';
import Styled from 'styled-components';

const SubFooterContainer = Styled.div`     
    margin: 0 auto;    
    text-align: center;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,1); 
    font-size: 10px;   
    color: rgba(256,256,256,0.7);
    z-index: 999;
    & > a:hover {
        trasition: color 1.5s;
        color: rgba(256,256,256,1);
    }           
`;

 function SubFooter() {
    return (
        <SubFooterContainer className="w-full py-[1em] pb-[2.5em] lg:px-0 lg:py-0 lg:pb-0"> 
            <a href="https://celedonio.digital" target="_blank" rel="noopener noreferrer">Website Developed By Celedonio Digital</a>
        </SubFooterContainer>
    );
}

export default SubFooter;