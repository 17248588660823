
import React from 'react';
import Styled from 'styled-components';

const SponsorsContainer = Styled.div`
    width: 100%;    
    margin: 0 auto;
    align-items: center;
    justify-content: center; 
    padding-bottom: 5em;
    background-color: rgba(244,244,244,0.5);
`;

const SponsorsGrid = Styled.div` 
    margin: 0 auto;
`;

const Sponsoritems = Styled.div`    
    margin: 0.3em;    
`;
const SponsorLink = Styled.a`
    border: 0;
`;
const SponsorImg = Styled.img`    
    object-fit: fill;
    border-radius: 0.5em;       
    &:hover {
        transition: filter 0.5s;
        filter: grayscale(0); 
    }
`;
const SponsorTitle = Styled.div`
    margin: 0 auto;
    font-size: 2em;
    padding: 1em;
`;
 
function Sponsors() {
    return (                
        <SponsorsContainer className="text-center min-h-[8em] p-[2em] lg:p-[2em] lg:min-h-[10em]">
            <SponsorTitle >Sponsors</SponsorTitle>
            <SponsorsGrid className="grid grid-flow-rows gap-10 lg:py-5 lg:grid-column-1 lg:grid-flow-col lg:gap-1 lg:w-[80%]">    
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="http://www.fmgnz.com" target="_blank">
                        <SponsorImg src="/sponsors/fmg.png" alt="Flexi Motor Group" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>                                                                                                                  
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://businessph.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/businessph.png" alt="Macz Films" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>                                                
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://lasa.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/lasa.png" alt="LASA Accounting" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems> 
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.facebook.com/sharlenebuenaharcourts/" target="_blank">
                        <SponsorImg src="/sponsors/sharlene.png" alt="Sharlene Buena Harcourts Grenadier" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>  
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.facebook.com/groups/238413892110579/user/100073719367430/" target="_blank">
                        <SponsorImg src="/sponsors/bundal-painting-ltd.png" alt="Bundal Painting Ltd." className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems> 
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://3523entertainment.carrd.co/" target="_blank">
                        <SponsorImg src="/sponsors/3523.png" alt="Bundal Painting Ltd." className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>            
            </SponsorsGrid>     
            <SponsorsGrid className="grid grid-flow-rows gap-10 pt-8 lg:py-5 lg:grid-column-1 lg:grid-flow-col lg:gap-1 lg:w-[53%]">    
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.xyra.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/xyra-savemart.png" alt="Xyra Savemart" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.nzmortgages.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/nz-mortgages.png" alt="NZ Mortgages" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>  
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.mdslaw.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/mds-law.png" alt="NZ Mortgages" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>
                <Sponsoritems className="w-[100%] lg:w-[200px] lg:h-[130px]">
                    <SponsorLink href="https://www.manilagrill.co.nz/" target="_blank">
                        <SponsorImg src="/sponsors/manila-grill.png" alt="Manila Grill" className="lg:w-[400px] lg:h-[150px] lg:filter lg:grayscale" />
                    </SponsorLink>
                </Sponsoritems>                                                                                                                                            
            </SponsorsGrid>     
        </SponsorsContainer>           
    );
}

export default Sponsors;