
import React, { useEffect } from 'react';
import Styled from 'styled-components';
import {
  Dropdown,
  initTE,
} from "tw-elements";

initTE({ Dropdown });

const Nav = Styled.div`
    margin: 0 auto;
    padding: 0.5em 0;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;        
    color: #FFF;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
`;

const Link = Styled.a`    
    font-weight: 600;
    color: rgba(255,255,255,0.6);
    transition: 0.5s;
    letter-spacing: 1px;
    position: relative;
    &:hover {        
        color: rgba(255,255,255,1);
    }
`;

const Linkdd = Styled.div`    
    font-weight: 600;
    color: rgba(255,255,255,0.6);
    transition: 0.5s;
    letter-spacing: 1px;
    position: relative;
    cursor: pointer;
    &:hover {        
        color: rgba(255,255,255,1);
    }
`;

const LinkSub = Styled.a`    
    font-size: 1em;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    transition: 0.5s;
    letter-spacing: 1px;
    position: relative;
    padding: 0.3em 0.2em;
    z-index: 1000;
    &:hover {        
        color: rgba(255,255,255,1);
    }
    &.bb {
      border-bottom: 0.5px solid rgba(255,255,255,0.3);  
    }
`;

const Drpdwn = Styled.div`
    z-index: 999;    
    border-radius: 3%;
    text-align: left;    
    height: auto;
    background-color: rgba(0,0,0,0.9);
`;

function Menu() {    

    useEffect(() => {
        const ddl1 = document.querySelector('#dropDownLink1') as HTMLElement;
        const ddm1 = document.querySelector('#dropDownMenu1') as HTMLElement;           
        if (ddl1 !== undefined && ddm1 !== undefined) {            
            ddl1.addEventListener('click', (e) => {      
                e.preventDefault();                                
                ddm1.style.top = (ddl1.offsetTop  + 40) + 'px';
                ddm1.style.left = ddl1.offsetLeft + 'px';
                if (ddm1.classList.contains('hidden')) {
                    ddm1.classList.remove('hidden');                    
                }
                else {
                    ddm1.classList.add('hidden');                    
                }               
            });
            window.addEventListener('click', (e) => {
                let varx = e.target as HTMLElement;
                if (!varx.classList.contains('ddm')) {
                    ddm1.classList.add('hidden');
                }                
            });
        }        
    }, []);  

    /* <Link href="https://docs.google.com/forms/d/e/1FAIpQLSeiKJZjtUfjnxV8hCuFUU3dIgAE8c1U2Du_VBTNjj7SA3QR4Q/viewform" target="_blank" rel="noopener noreferrer" 
            className="block px-[0.4em] lg:px-[1.2em] lg:text-[1.2em]">Registration</Link> */

    return (        
        <Nav className="sticky top-0 relative flex">
            <Link href="/" className="block px-[0.4em] lg:px-[1.2em] lg:text-[1.2em]">Home</Link>
            <Link href="/registration" 
            className="block px-[0.4em] lg:px-[1.2em] lg:text-[1.2em]">Registration</Link>
            <Linkdd 
                id="dropDownLink1"                
                className="ddm block px-[0.4em] lg:px-[1.2em] lg:text-[1.2em]">
                Association
                <span className="float-right mt-1 lg:mt-1 ml-0 lg:ml-1 lg:mr-1 mr-2 w-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-4 w-4 lg:h-5 lg:w-5">
                    <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                </svg>
                </span>                                                               
            </Linkdd>                  
            <Drpdwn 
                id="dropDownMenu1"
                className="ddm absolute hidden px-[0.3em] min-w-[10em] lg:min-w-[15em]">
                <LinkSub href="/programme" className="block text-left bb">Programme</LinkSub>
                <LinkSub href="/rules" className="block text-left bb">Rules</LinkSub>
                <LinkSub href="/games" className="block text-left bb">Games</LinkSub>  
                <LinkSub href="/teams" className="block text-left bb">Teams</LinkSub>
                <LinkSub href="/meetings" className="block text-left">Minutes of Meeting</LinkSub>                       
            </Drpdwn>     
            <Link href="/contact" className="block px-[0.4em] lg:px-[1.2em] lg:text-[1.2em]">Contact</Link>                                 
        </Nav>
    );
}

export default Menu;