
import React from 'react';
import Styled from 'styled-components';

const FooterContainer = Styled.div`     
    margin: 0 auto;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0, 0.7);               
`;

const FooterSubContainer = Styled.div`
    margin: 0 auto;           
`;

const FooterItems = Styled.div`    
    margin: 0.2em;       
    & > img {
        margin: 0 auto;
    }
    & > a {
        margin: 2px 0;        
        display: inline-block;        
        font-size: 1.2em;
        color: rgba(255,255,255,0.6);
        transition: 0.5s;
        letter-spacing: 1px;
        &:hover {        
            color: rgba(255,255,255,1);
        }
    }
    & > div {
        color: #FFF;
        & > i {
            margin: 0 0.5em;
        }
    }
`;

const FooterMap = Styled.iframe`
    border: 0;    
    height: 150px;
    border-radius: 0.4em;
`;
 
function Footer() {
    return (
        <FooterContainer className="w-full py-[1em] lg:px-[10em] lg:py-[2em]"> 
            <FooterSubContainer className="w-[98%] lg:flex lg:w-[80%]">
                <FooterItems className="grow-0 basis-1/1 center mx-2em">
                    <img src="/fbac-footer.png" alt="FBAC" className="h-[150px] rounded-s" />
                </FooterItems>
                <FooterItems className=" px-2 text-center lg:flex-1 lg:text-left">
                    <a href="/" className="pt-2 px-3 lg:px-2">Home</a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeiKJZjtUfjnxV8hCuFUU3dIgAE8c1U2Du_VBTNjj7SA3QR4Q/viewform" target="_blank" rel="noopener noreferrer" className="pt-2 px-3 lg:px-2">Registration</a>
                    <a href="/programme" className="pt-2 px-3 lg:px-2">Programme</a>
                    <a href="/rules" className="pt-2 px-3 lg:px-2">Rules</a>
                    <a href="/games" className="pt-2 px-3 lg:px-2">Games</a>
                    <a href="/teams" className="pt-2 px-3 lg:px-2">Teams</a>
                    <a href="/meetings" className="pt-2 px-3 lg:px-2">Minutes of Meeting</a>
                    <a href="/contact" className="pt-2 px-3 lg:px-2">Contact</a>
                    <div className="px-1 pt-2 select-none">
                        <i className="fa-regular fa-map fa-lg"></i><span>59c Sturrocks Road Redwood, Christchurch Canterbury NZ</span>
                    </div>
                    <div className="px-1 py-2 select-none">
                        <i className="fa-solid fa-phone fa-lg"></i><span>Ph.064275348133</span>
                    </div>
                    <div className="px-3 py-2">
                        <a href="https://www.facebook.com/groups/238413892110579" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook fa-lg"></i></a> 
                    </div>                    
                </FooterItems>
                <FooterItems className="lg:flex-1 lg:px-1">
                    <FooterMap 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.310736466331!2d172.60911557746587!3d-43.47498517828515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318c78954847cf%3A0x31ff9fa21f2204bb!2s59%20Sturrocks%20Road%2C%20Redwood%2C%20Christchurch%208051!5e0!3m2!1sen!2snz!4v1688850249522!5m2!1sen!2snz" 
                    loading="lazy" className='w-[100%] lg:w-[500px]'></FooterMap>                       
                </FooterItems>
            </FooterSubContainer>           
        </FooterContainer>
    );
}

export default Footer;