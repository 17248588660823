
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import CryptoJS from "crypto-js";
import Axios from 'axios';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

function Contact() {

    const HostNm = process.env.REACT_APP_HOST_NAME;
    const Apikey = process.env.REACT_APP_API_KEY;  
    const ApiUrl = process.env.REACT_APP_API_URL; 
    const ReCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const [enquiryColor, setEnquiryColor] = useState("opacity-0");
    const [enquiryRes, setEnquiryRes] = useState("");
    const [enquiryName, setEnquiryName] = useState("");
    const [enquiryEmail, setEnquiryEmail] = useState("");
    const [enquirySubject, setEnquirySubject] = useState("");
    const [enquiryMessage, setEnquiryMessage] = useState("");   
    const [enquiryToken, setEnquiryToken] = useState((value) => {
        return value;
    });
    const [enquiryCsrf, setEnquiryCsrf] = useState(undefined);  
    useEffect(() => {
        const fetchData = async () => {       
        try {
            const response = await Axios.post(ApiUrl + 'csrf', { 
                payload: 'csrf'
            });
            setEnquiryCsrf(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchData();
    }, []);
    
    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();  
        if (enquiryEmail.length && enquiryEmail.length && enquiryMessage.length) {      
            var enquiryUrl = ApiUrl + 'contact/send-mail';
            var enquiryData = btoa(JSON.stringify({
                pkey: Apikey,
                host: HostNm,
                body: {
                nme: enquiryName,
                eml: enquiryEmail,
                sbj: enquirySubject,
                msg: enquiryMessage,
                tkn: enquiryToken
                }
            }));
            var enquiryHeaders = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Requested-With, Authorization',
                'Access-Control-Allow-Origin': '*',    
                'Access-Control-Max-Age': '1000',
                'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
                'Accept': 'application/json',
                'Csrf-Key': enquiryCsrf
            }
            var enquiryEncr = CryptoJS.AES.encrypt(enquiryData, Apikey).toString();
            try {
                await Axios.post(enquiryUrl, {
                payload: enquiryEncr
                }, {
                headers: enquiryHeaders
                }).then((response) => {
                if (response.data.success) {
                    if (response.data.payload.success && response.data.payload !== null) {               
                        setEnquiryName('');
                        setEnquiryEmail('');
                        setEnquirySubject('');
                        setEnquiryMessage('');
                        setEnquiryToken(''); 
                        setEnquiryColor('opacity-100 bg-green-50 text-green-800');            
                    }
                    else {
                        setEnquiryColor('opacity-100 bg-red-50 text-red-800');            
                    }
                    setEnquiryRes(response.data.payload.message.trim()); 
                }
                })
            } catch (error) {
                console.log(error);
            }      
        }
    }

    return (
        <div id="Contact"className="contact anchor">
            <Header />
            <Menu />
            <div className="py-[1em] lg:py-16 px-4 mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center">Contact Us</h2>
                <div className={
                enquiryRes !== null ? 
                enquiryColor + " response min-h-[1.3em] my-[0.5em] text-[1.2em] lg:text-[1.5em] py-2.5 center" : 
                "response min-h-[1.3em] my-[0.5em] text-[1.5em] py-2.5 center" }
                >{enquiryRes}</div>
                <form action="#" className="space-y-8" onSubmit={handleSubmit}>
                <div>                    
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-left">
                        Your Name
                        </label>
                        <input type="name" 
                        value={enquiryName}
                        onChange={(e) => setEnquiryName(e.target.value)}
                        id="name" className="
                        shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                        dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" 
                        placeholder="Firstname Lastname" required />
                    </div>
                    <div>                    
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-left">
                        Your Email
                        </label>
                        <input type="email" 
                        value={enquiryEmail}
                        onChange={(e) => setEnquiryEmail(e.target.value)}
                        id="email" className="
                        shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                        dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" 
                        placeholder="user@email.com" required />
                    </div>
                    <div>
                        <label htmlFor="subject" className="block mb-2 text-sm font-medium text-left">
                        Subject
                        </label>
                        <input type="text" 
                        value={enquirySubject}
                        onChange={(e) => setEnquirySubject(e.target.value)}
                        id="subject" className="
                        block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 
                        shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 
                        dark:focus:border-primary-500 dark:shadow-sm-light" 
                        placeholder="Let us know how we can help you" required />
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-left">
                        Your Message
                        </label>
                        <textarea 
                        value={enquiryMessage}
                        onChange={(e) => setEnquiryMessage(e.target.value)}
                        id="message" rows={6} className="
                        block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border 
                        border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 
                        dark:focus:border-primary-500" placeholder="Leave your enquiry here..."></textarea>
                    </div>
                    <div className="sm:col-span-2 px-[1em] lg:px-[14em]">
                    <ReCAPTCHA                    
                        sitekey={ReCaptchaKey}
                        onChange={setEnquiryToken}
                    />                 
                    </div>
                    <button type="submit" id="sendBtn" className="
                    py-3 px-5 text-sm font-medium text-center text-gray-500 rounded-lg bg-gray-600  
                    hover:bg-primary-800 hover:text-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 
                    md:w-fit">Send Enquiry</button> 
                    <input type="hidden" value={enquiryCsrf} />                               
                </form>
            </div>  
            <Sponsors />
            <Footer />
            <SubFooter />          
        </div>                    
    );

}

export default Contact;