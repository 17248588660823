
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from "react-player";
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

const Spanner = Styled.span`
    text-shadow: 1px 1px #FFF;
`;
const Videox = Styled.div`
    text-align: center;
    margin: 0 auto;
    position: relative;
    & > h2 {
        text-shadow: 0 0 1px #000, 0 0 2px #000;
    }
    
`;

/*
<img src="/fbac-league.png" className="m-[1em] w-[24em] lg:w-[35em] lg:my-[3em] mx-auto rounded-[1em]" />                  
            <Videox className="w-full lg:w-fit lg:pb-[22em] h-fit place-self-center py-[2em] pb-[8em] relative">
                <h2 className="w-full place-self-center text-[2em] lg:text-[3em] text-orange-500">Players Of The Game</h2>
                <ReactPlayer
                    url="https://www.facebook.com/titus.navacilla/videos/2310815269307857"
                    controls className="lg:w-[100%] lg:h-[100%] lg:max-w-[100%] w-[100%] h-[100%] max-w-[100%]"
                />
            </Videox>
            <div className="my-[3em] bg-slate-200 py-[2em]">
                <h2 className="text-center text-[2em]">Schedules Season 1</h2>
                <div className="lg:flex lg:flex-wrap lg:w-[80%] w-[99%] m-auto">
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Aug 12, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> vs <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> vs <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> vs <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> vs <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Aug 19, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> vs <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> vs <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> vs <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> vs <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Aug 26, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> vs <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> vs <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> vs <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Sept 2, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> vs <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> vs <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> vs <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Sept 9, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> vs <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> vs <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> vs <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Sept 16, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> vs <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> vs <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> vs <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Sept 23, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> vs <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> vs <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> vs <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> vs <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Sept 30, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> vs <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> vs <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> vs <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> vs <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                        </div>
                    </div>
                    <div className="m-[1em]">
                        <h3 className="lg:text-[1.3em] text-[2em]">Oct 7, 2023</h3>
                        <div className="my-[1em] leading-[2.8em]">
                            Game 1 - 5pm-6pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-purple-700 p-[0.5em] rounded-[0.3em] font-bold">Purple</Spanner> vs <Spanner className="bg-blue-900 p-[0.5em] rounded-[0.3em] font-bold">Blue</Spanner> <br />
                            Game 2 - 6pm-7pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-gray-400 p-[0.5em] rounded-[0.3em] font-bold">Grey</Spanner> vs <Spanner className="bg-red-500 p-[0.5em] rounded-[0.3em] font-bold">Red</Spanner> <br />
                            Game 3 - 7pm-8pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-white p-[0.5em] rounded-[0.3em] font-bold">White</Spanner> vs <Spanner className="bg-red-700 p-[0.5em] rounded-[0.3em] font-bold">Maroon</Spanner> <br />
                            Game 4 - 8pm-9pm &nbsp;&nbsp;&nbsp;: &nbsp; <Spanner className="bg-blue-400 p-[0.5em] rounded-[0.3em] font-bold">Light Blue</Spanner> vs <Spanner className="bg-gray-600 p-[0.5em] rounded-[0.3em] font-bold">Black</Spanner> <br />
                            Game 5 - 9pm-10pm &nbsp;: &nbsp; <Spanner className="bg-yellow-300 p-[0.5em] rounded-[0.3em] font-bold">Yellow</Spanner> vs <Spanner className="bg-green-500 p-[0.5em] rounded-[0.3em] font-bold">Green</Spanner> <br />
                        </div>
                    </div>
                </div>
            </div>
*/

function Games() {
    return (
        <div id="Games" className="Games anchor">
            <Header />
            <Menu />      
            <div className="my-[3em] bg-slate-200 py-[2em]">
                <h2 className="text-center text-[2em]">Schedules Season 2</h2>
                <div className="lg:flex lg:flex-wrap lg:w-[80%] w-[99%] m-auto">
                    <img src="/images/games/game1.png" className="m-[1em] w-[95%] lg:w-[90%] lg:mt-[3em] mx-auto rounded-[1em]" />
                    <img src="/images/games/game2.png" className="m-[1em] w-[95%] lg:w-[90%] lg:mt-0 mx-auto rounded-[1em]" />
                </div>
            </div>            
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Games;