
import React, { useEffect, useRef, useState } from 'react';
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

function Programme() {
    return (
        <div id="Games" className="Games anchor">
            <Header />
            <Menu />
            <img src="/images/programme/program1.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em]" />
            <img src="/images/programme/program2.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em]" />
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Programme;