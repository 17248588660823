
import React, { useEffect, useRef, useState } from 'react';
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

const TeamsContainer = Styled.div`
    margin: 0 auto;
`;

const TeamName = Styled.h2`
    font-weight: bold;
    font-size: 1.2em;
    color: white;
    border-bottom: 1px solid black;
    &.dark {
        color: black;
    }    
`;

const PlayerList = Styled.div`
    width: 100%;
    border-bottom: 1px solid black;
    padding: 0.3em;
    &.no-bb {
        border-bottom: 0;
    }
`;

function Teams() {
    return (
        <TeamsContainer id="Teams" className="Teams anchor">
            <Header />
            <Menu />             
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center py-[1.5em]">2023 Season 2 Teams</h2>
            <div className="grid grid-flow-rows gap-8 lg:grid-cols-4 lg:grid-flow-rows lg:gap-2 lg:w-[80%] mx-auto my-7 mt-0">
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-orange-500 text-center rounded-t-md">Orange</TeamName>    
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Allan Desquitado</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Aaron Enriques</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Darien Banglay</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Danicko Mancenido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Kevin Alferos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Charles Pardo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Emzo Macion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Joboy Castro</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Ronnie Galve</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Donn Hendrix Zafra</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Edmar Benmejo</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Andrew Cuertero</div>  
                    </PlayerList>
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-yellow-200 dark text-center rounded-t-md">Yellow</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jhanz Yap</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Jan Ladroma</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Vernie Bungcaras</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Vince Pastor</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Paolo Castillo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Joey Lasin</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Julian Maranan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Harrelson Pisano</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Vico Pastor</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Karl Rodrigues</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Zaint Agpasa</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">John Ompad</div>  
                    </PlayerList>                       
                </div>                
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-purple-700 text-center rounded-t-md">Purple</TeamName>  
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Erwin Celario</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">AJ Plaza</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Vince Canoy Keir</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Tackz Abrenica</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Marky Cobarrubias</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Kimbron Tumbaga</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Isaac Real</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Mickoy Juego</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Chito Amutan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">James Manjares</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Zion Buenaventura</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Norman Mejia</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Mark Garcia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Mark Manarang</div>  
                    </PlayerList>   
                </div>
                <div className=" m-[0.2em] lg:flex-1">
                    <TeamName className="bg-blue-400 text-center rounded-t-md">Sky Blue</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">JayR Montano</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Inoc</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Jomane Tapao</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Sidney Carmonte</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Ross Campos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Nora</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Ron Daryl Iyo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Wilson Onia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Jay Santos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Kryst Torralba</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Jang Jang Morta</div>  
                    </PlayerList>   
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Brix Mutia</div>  
                    </PlayerList>   
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Ahrjae Asuncion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Sugar Luceno</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#15</div>                        
                        <div className="text-left mx-1 flex-1">Drews Arellano</div>  
                    </PlayerList>                
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-blue-900 text-center rounded-t-md">Blue</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Sid Lapojapo</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Moises Lapojapo</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Sean Regalado</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Reden Regalado</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Boyet Bundal</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Rene Bundal</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Sherwin Mananquil</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Ryan Polido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Harold Valiente</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Ony Paguyo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Cedric Paguyo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Kyle Justine Galendez</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Julius Magallanes</div>  
                    </PlayerList>     
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Glenn Magallanes</div>  
                    </PlayerList>
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-gray-900 text-center rounded-t-md">Black</TeamName> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Ranil Quilaton</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Jason Dasalla</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Glenn Loquia</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Andrei Valeros</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Mark Dion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Louise Duka</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Nico Mancao</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Joel Flores</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Jose Flores</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Emmanuel Bunag</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Justine Liac</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Allan Celedonio</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Arelle David</div>  
                    </PlayerList>  
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Sherwin Ancog</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#15</div>                        
                        <div className="text-left mx-1 flex-1">Trevor Andal</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#16</div>                        
                        <div className="text-left mx-1 flex-1">Dwight Ramos</div>  
                    </PlayerList>
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-red-900 text-center rounded-t-md">Maroon</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Josel Reyes Andal</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Miguel Andal</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Joshua Briones</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Gavin Torres</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Rocky Romero</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Romualdo Santos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Edsel Alagdon</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Jessie Barbero</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Joselito Clarino</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Matthew Macaspac</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Bernie Oyardo</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Marion Torralba</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Alver Tonido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Alex Lane</div>  
                    </PlayerList>    
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-gray-100 dark text-center rounded-t-md">White</TeamName> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jake Libre</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Ruzel Garcia</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Edison Valibia Jr.</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Kirk John Perocho</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Cliff Lasmarias</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Ezekiel Incapas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Robbert Magallanes</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Carlo Reyes</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Gabriel Canillo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Aris Arellano</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Rudy Premacio</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Raffy Presente</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Nino Richard Matining</div>  
                    </PlayerList>  
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Kurt Luna</div>  
                    </PlayerList>
                </div>
                <div className="m-[0.2em] lg:flex-1">                
                    <TeamName className="bg-green-500 text-center rounded-t-md">Green</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Mike Viado</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Ludemar Cagbabanua</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Villagonzalo</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Johnny Gados</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Rafael Ag</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Arvin Bombita</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Daryl Aying</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Christian Mendiola</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">John Hill Cadagas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Palomado</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Ruiz Lasquites</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Elenzer Separa</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Llosa</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Andie Leynesa</div>  
                    </PlayerList>                    
                </div>
                <div className="m-[0.2em] lg:flex-1">                
                    <TeamName className="bg-lime-400 text-center rounded-t-md">Yellow Green</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Arnie Ronquillo</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Manny Recamadas</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Clifford Botards</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Manila</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Michael Mercurio</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Bamb Dave</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Sire Osiva Gaballa</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Vincent Angelo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Amir</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Kirk Isurda</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Louise Navarro</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">John Trix Manarang</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Carl Guiwan</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Salcedo Marcelo</div>  
                    </PlayerList>                    
                </div>
                <div className="m-[0.2em] lg:flex-1">                
                    <TeamName className="bg-gray-400 text-center rounded-t-md">Gray / Silver</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Timothy Estorco</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Jan Sunga</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Roi Espoltero</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Prima</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Stefan Lasa</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Philip Mejorada</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Joshua Sanico</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Richard Bantasan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Cedric Diputado</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Philip Mejorada Jr.</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Sidney Dela Cruz</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Gillian Castroverde</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Alfred Relen</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Joel Mendoza</div>  
                    </PlayerList>                    
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-red-500 text-center rounded-t-md">Red</TeamName>    
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Allen Rivera</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Vince Roman</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Raymart Batac</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Francis Manarang</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Sebastian Yambao</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Aries Yambao</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Joshua Aguilar</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Micheal Villegas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Jose Lee</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Froilan Chavez</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Mark Saclote</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Owen Nicolas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Justine Pabaira</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#14</div>                        
                        <div className="text-left mx-1 flex-1">Tol Milford</div>  
                    </PlayerList>
                </div>
            </div>
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center py-[1.5em]">2023 Season 1 Teams</h2>
            <div className="grid grid-flow-rows gap-8 lg:grid-cols-4 lg:grid-flow-rows lg:gap-2 lg:w-[80%] mx-auto my-7 mt-0">                
                <div className="m-[0.2em] lg:flex-1">                
                    <TeamName className="bg-green-500 text-center rounded-t-md">Green</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Timothy Estorco</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Stefan Lasa</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Roi Vincent Besas Espoltero</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Nicklglen Castroverde</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Luis Leh</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Philip Mejorada</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Joshua Sanico</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Jan Sunga</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Adam Prima</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Joel Power Caballero Flores</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Richard Bantasan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Raphael Cedric Diputado</div>  
                    </PlayerList>                    
                </div>                                              
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-yellow-200 dark text-center rounded-t-md">Yellow</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jay R Montano</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Kurt Luna</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">RossGodo Rose Campos</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Kryst Torralba</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Wilson Onia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Jeff Nora</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Jomarie Tapao</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Jay Santos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Christian Dave Quilang</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Inoc</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Sidney Caramonte</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Marshall Bonifacio</div>  
                    </PlayerList>   
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-red-500 text-center rounded-t-md">Red</TeamName>    
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jake Junvie Libre</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">John Ruzel Garcia</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Roberto Magallones</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Ezekiel Incapas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Edison Valibia Jr.</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Jay Patrick Flores</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Josh Halog</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Chris Manuel</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">KirkJohn Perucho</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Richard Sesaldo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Cliff Lasmarias</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Carlo Reyes</div>  
                    </PlayerList>
                </div>                
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-gray-900 text-center rounded-t-md">Black</TeamName> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Sid Lapojapo</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Michael Panganiban</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Reden Regalado</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Orly Paguyo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Mark Llovido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Kim Tumbaga</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Bobby Millano</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Renz Bundal</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Harold Valiente</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Jerry Macatangay</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Rylu Dequita</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Sherwin Mananquil</div>  
                    </PlayerList>  
                </div>                
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-purple-700 text-center rounded-t-md">Violet</TeamName>  
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Arnie Matty</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">James Rensulat</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Corpuz Dollaga</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Denis Gonzales</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Benedict Duka</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Ray Salvacion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Clifford Botards</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Amir Shaban</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Manila</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Phel Abella Perater</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Jonathan Lizondra</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Michael Mercurio</div>  
                    </PlayerList>    
                </div>
                <div className=" m-[0.2em] lg:flex-1">
                    <TeamName className="bg-gray-400 text-center rounded-t-md">Gray</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jacinto Llesis</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Christian Mendiola</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Eleazar Separa</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Espiritu Cristian</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">John Jeffrey Tanhueco</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Justin Liac</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Levy Moscosa Lagrosas</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Richard Matining</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Nikko Castroverde</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Nos Nour</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Orlee Ognolino</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Ronald Plandes</div>  
                    </PlayerList>    
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-red-900 text-center rounded-t-md">Maroon</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Quilaton Ranil</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Karl Jerard Bantasan</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Jayson Dasalla</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Marc Dion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Ryan Polido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Glen Coquia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Mikko Mangcao</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Louise Duka</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Aldrei Valeros</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Mark Chua</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Jef Llosa</div>  
                    </PlayerList> 
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">James Go</div>  
                    </PlayerList>    
                </div>
                <div className=" m-[0.2em] lg:flex-1">
                    <TeamName className="bg-blue-400 text-center rounded-t-md">Light Blue</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Allan Desquitado</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Bonn Hendrix</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Aaron Akins Enriquez</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Banglay</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Mathieux</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Enzo Macion</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Joboy Castro</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Danicko Mancenido</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Scott Kevin Samera</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Charles Pardo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Daniel Salvadora</div>  
                    </PlayerList>                      
                </div>
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-blue-900 text-center rounded-t-md">Blue</TeamName>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Allen Rivera</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Aegy</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Ashton</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Brix Mutia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Adrian Palomado</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Alfred Carumba</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Mark Anthon</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Vince Roman</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Angelo Santos</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Batac</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Japs Garcia</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Dadi Cool</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">ABCD</div>  
                    </PlayerList>     
                </div>  
                <div className="m-[0.2em] lg:flex-1">
                    <TeamName className="bg-gray-100 dark text-center rounded-t-md">White</TeamName> 
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em] ">#1</div>                        
                        <div className="text-left mx-1 flex-1">Jhanz Rivera</div>
                        <div className="text-center flex-end"><i className="fa-solid fa-basketball fa-lg text-[#f97316]"></i></div>
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#2</div>                        
                        <div className="text-left mx-1 flex-1">Tackz Abrenica</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#3</div>                        
                        <div className="text-left mx-1 flex-1">Paolo Castillo</div>                        
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#4</div>                        
                        <div className="text-left mx-1 flex-1">Julian Maranan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#5</div>                        
                        <div className="text-left mx-1 flex-1">Mikoy Juego</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#6</div>                        
                        <div className="text-left mx-1 flex-1">Vince Pastor</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#7</div>                        
                        <div className="text-left mx-1 flex-1">Elmo Baclan</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#8</div>                        
                        <div className="text-left mx-1 flex-1">Jeffrey Villagonzalo</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#9</div>                        
                        <div className="text-left mx-1 flex-1">Virnie Bungcaras</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#10</div>                        
                        <div className="text-left mx-1 flex-1">Rajon Josh Joey Lasin</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#11</div>                        
                        <div className="text-left mx-1 flex-1">Jan Ladroma</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#12</div>                        
                        <div className="text-left mx-1 flex-1">Harrelson Pisano</div>  
                    </PlayerList>
                    <PlayerList className="flex flex-no-wrap no-bb">                        
                        <div className="text-right mx-1 flex-0 w-[1.5em]">#13</div>                        
                        <div className="text-left mx-1 flex-1">Benjie Cruzat</div>  
                    </PlayerList>  
                </div>
            </div>   
            <Sponsors />
            <Footer />
            <SubFooter />          
        </TeamsContainer>
    );
}

export default Teams;