
import React, { useEffect, useRef, useState } from 'react';
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';


function Rules() {
    return (
        <div id="Games" className="Games anchor">
            <Header />
            <Menu />
            <img src="/images/rules/rule1.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em] border" />
            <img src="/images/rules/rule2.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em] border" />
            <img src="/images/rules/rule3.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em] border" />
            <img src="/images/rules/rule4.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em] border" />
            <img src="/images/rules/rule5.png" className="m-[1em] w-[24em] lg:w-[40em] lg:my-[3em] mx-auto rounded-[1em] border" />            
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Rules;