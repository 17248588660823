
import React from 'react';
import Styled from 'styled-components';

const HeaderContainer = Styled.div`    
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;    
`;
 const HeaderLogo =  Styled.img`
    height: auto;
 `;
 const HeaderTitle = Styled.div`
    width: 6em;
    text-align: left;    
    font-weight: bold;
    font-family: 'Bebas Neue', sans-serif; 
    font-weight: bold;   
    letter-spacing: 3px;
    color: #000; 
    line-height: 1.1em;
 `;

function Header() {
    return (
        <HeaderContainer id="Home" className="py-[0.5em] lg:py-[1em]">
            <HeaderLogo src="/fbac-logo.png" className="w-[160px] lg:w-[180px]"></HeaderLogo>
            <HeaderTitle className="text-[2em] lg:text-[2.2em]">
                Filipino Basketball Association Christchurch
            </HeaderTitle>            
        </HeaderContainer>
    );
}

export default Header;