import React from 'react';
import {
  createBrowserRouter,
  RouterProvider  
} from "react-router-dom";
import Styled from 'styled-components';
import './App.css';
import Home from './pages/home';
import Programme from './pages/programme';
import Rules from './pages/rules';
import Games from './pages/games';
import Teams from './pages/teams';
import Meetings from './pages/meetings';
import Contact from './pages/contact';
import Registration from './pages/registration';

const Container = Styled.div`
  ${({ theme }) => theme.body}
  width: 100%;
  height: 100vh;  
  margin: 0 auto;
  padding: 1em 0;
  background-image: url('/header.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;    
`;

const router = createBrowserRouter([  
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/programme",
    element: <Programme />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/games",
    element: <Games />,
  },
  {
    path: "/teams",
    element: <Teams />,
  },
  {
    path: "/meetings",
    element: <Meetings />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },    
  {
    path: "*",
    element: <Home />,
  },
]);

function App() {
  return (
    <Container>        
        <RouterProvider router={router} />        
    </Container>    
  );  
}

/*
function App() {
  return (
    <Container>        
      <Header />
      <Menu />
      <Home />
      <Sponsors />
      <Games />
      <Teams />
      <Contact />      
      <Footer />
      <SubFooter />         
    </Container>    
  );  
}
*/


export default App;
