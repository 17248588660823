
import React from 'react';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

function Meeting() {
    return (
        <div id="Games" className="Games anchor">
            <Header />
            <Menu />
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center py-[1.5em]">Minutes of Meeting</h2>            
            <div className="lg:flex justify-center mb-[2em]">
                <div className="text-center  m-4">
                    <a href="/meetings/mm-ird.pdf" target="_blank">
                        <i className="fa-solid fa-file-pdf fa-4x"></i><br />
                        IRD
                    </a>
                </div>
                <div className="text-center m-4">
                    <a href="/meetings/mm-27-7-23.pdf" target="_blank">
                        <i className="fa-solid fa-file-pdf fa-4x"></i><br />
                        27/07/2023
                    </a>
                </div>
                <div className="text-center  m-4">
                    <a href="/meetings/mm-12-7-23.pdf" target="_blank">
                        <i className="fa-solid fa-file-pdf fa-4x"></i><br />
                        12/07/2023
                    </a>
                </div>
                <div className="text-center  m-4">
                    <a href="/meetings/mm-30-6-23.pdf" target="_blank">
                        <i className="fa-solid fa-file-pdf fa-4x"></i><br />
                        30/06/2023
                    </a>
                </div>
                <div className="text-center  m-4">
                    <a href="/meetings/mm-31-10-23.pdf" target="_blank">
                        <i className="fa-solid fa-file-pdf fa-4x"></i><br />
                        31/10/2023
                    </a>
                </div>                
            </div>
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Meeting;