
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from "react-player";
import Styled from 'styled-components';
import Header from './../partials/header';
import Menu from './../partials/menu';
import Sponsors from './../partials/sponsors';
import Footer from './../partials/footer';
import SubFooter from './../partials/subfooter';

const HomeContainer = Styled.div`         
    margin: 0 auto;    
    align-items: left;
    justify-content: start;    
    letter-spacing: 0.5px;    
`;

const Playaz = Styled.a`
    height: auto;        
    font-weight: bold;
    color: gray;
    transition: 0.5s;    
    text-decoration: underline;
    &:hover {        
        color: black;
    }    
`;

const Videox = Styled.div`
    text-align: center;
    margin: 0 auto;
    position: relative;
    & > h2 {
        text-shadow: 0 0 1px #000, 0 0 2px #000;
    }
    
`;

/* <Playaz href="https://docs.google.com/forms/d/e/1FAIpQLSeiKJZjtUfjnxV8hCuFUU3dIgAE8c1U2Du_VBTNjj7SA3QR4Q/viewform" target="_blank" rel="noreferrer">here.</Playaz> */

function Home() {
    return (
        <div className="Home">
            <Header />
            <Menu />
            <h2 className="w-full m-auto text-center">
                    <img src="/images/kingscup.png" alt="Kings Cup" className="m-auto"></img>
            </h2>
            <Videox className="w-full lg:w-fit h-fit place-self-center relative">                
                <ReactPlayer autoPlay
                    url="/videos/kingscup.mp4" playing={true}
                    controls className="lg:w-[100%] lg:h-[100%] lg:max-w-[100%] w-[100%] h-[100%] max-w-[100%]"
                />
            </Videox>
            <HomeContainer className="w-10/12 text-[1em] min-h-[10em] py-[1em] lg:text-[1.2em] lg:py-[2em] lg:min-h-[15em]">                
                Welcome to FBAC.basketball, the official website of the Filipino Basketball Association Christchurch! 
                We are your go-to source for everything related to Filipino basketball in Christchurch, New Zealand. 
                Whether you're a player, a fan, or simply interested in the vibrant basketball community, you've come to the right place.
                <br /><br />
                FBAC.basketball is dedicated to promoting the love and passion for basketball among Filipinos in Christchurch. 
                Our platform serves as a hub for news, updates, and announcements about local basketball leagues, tournaments, and events. 
                Stay informed about upcoming games, results, and the latest happenings in the basketball scene.
                <br /><br />
                Join us in celebrating the unity, camaraderie, and skill of a FBAC basketball players as they showcase their talents on the court. 
                Connect with fellow enthusiasts, engage in discussions, and share your own basketball experiences within our interactive community. 
                Whether you're looking to join a team, find training opportunities, or simply enjoy the sport, FBAC.basketball has something for everyone.
                <br /><br />                
                Explore our website to discover player profiles, team statistics, and in-depth analysis of games. 
                Subscribe to our facebook page and be part of the lively basketball conversation. 
                Together, let's support and elevate the Filipino basketball spirit in Christchurch.
                <br /><br />
                If you want to join the games sign up on our player registration form <Playaz 
                href="/registration" rel="noreferrer">
                    here.
                </Playaz>
                <br /><br />                
                Thank you for visiting FBAC.basketball, your premier destination for all things Filipino basketball in Christchurch. Let's dribble, shoot, and score together!
            </HomeContainer>
            <Sponsors />
            <Footer />
            <SubFooter />
        </div>
    );
}

export default Home;